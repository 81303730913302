import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './slices/user';
import customerReducer from './slices/customer';
import allUserReducer from './slices/allUser';
import createUserReducer from './slices/createUser';
import allContatcsReducer from './slices/allContacts';
import addContatcsReducer from './slices/addContact';
import createWBAccountReducer from './slices/createWBAccount';
import getTemplatesReducer from './slices/getAllTemplates';
import getWBAccountsReducer from './slices/getWBAccounts';
import sendMessageReducer from './slices/sendMessage';
import campaignDataReducer from './slices/campaignData';
import fromNumberReducer from './slices/fromNumber';
import sentCampaignReducer from './slices/sentCampaign';
import campaignListReducer from './slices/campaignList';
import accountStatsReducer from './slices/accountStats';
import chatMessagesReducer from './slices/chatMessages';
import directMessageReducer from './slices/directMessage';
import updateConversationReducer from './slices/updateConversation';
import campaignDetailsReducer from './slices/campaignDetails';
import campaignStatsReducer from './slices/campaignStats';
import chatList from './slices/chatList';
import mediaSaveReducer from './slices/mediaSave';
import conversationTagsReducer from './slices/ConversationTags';
import fileUploadReducer from './slices/fileUpload';
import configWebhookReducer from './slices/configWebhook';
import downloadAnalyitcsReducer from './slices/downloadAnalyitcs';
import notesReducer from './slices/getNotes';
import templateMasterDataReducer from './slices/templateMasterData';
import createTemplateReducer from './slices/createTemplate';
import groupOfContactReducer from './slices/allGroupsOfContact';
import contactsFromGroupReducer from './slices/addContactToGroup';
import quickReplyReducer from './slices/quickReply';
import assignReducer from './slices/assignFeature';
import blockContactReducer from './slices/contactBlock';
import downloadChatReducer from './slices/downloadChat';
import apiKeyReducer from './slices/apiKey';
import createRcsAccountReducer from './slices/createRcsAccount';
import getRcsAccountReducer from './slices/getRcsAccount';
import campaignRcsDataReducer from './slices/campaignRcsData';
import sentRcsCampaignReducer from './slices/sentRcsCampaign';
import campaignRcsListReducer from './slices/campaignRcsList';
import chatbotReducer from './slices/createChatBot';
import chatbotListReducer from './slices/chatbotList';
import downloadCenterReducer from './slices/downloadCenter';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  customer: customerReducer,
  allUser : allUserReducer,
  createUser:createUserReducer,
  addContact : addContatcsReducer,
  allContacts : allContatcsReducer,
  createWBAccount:createWBAccountReducer,
  createRcsAccount: createRcsAccountReducer,
  getTemplates :getTemplatesReducer,
  getWBAccount:getWBAccountsReducer,
  sendMessage:sendMessageReducer,
  campaignData:campaignDataReducer,
  fromNumber: fromNumberReducer,
  sentCampaign:sentCampaignReducer,
  campaignList:campaignListReducer,
  chatList : chatList ,
  accountStats :accountStatsReducer,
  chatMessagesList:chatMessagesReducer,
  directMessage:directMessageReducer,
  updateConversation:updateConversationReducer,
  campaignDetails:campaignDetailsReducer,
  campaignStats:campaignStatsReducer,
  mediaSave:mediaSaveReducer,
  conversationTags:conversationTagsReducer,
  fileUpload:fileUploadReducer,
  configWebhook:configWebhookReducer,
  downloadAnalyitcs:downloadAnalyitcsReducer,
  notes:notesReducer,
  templateMasterData : templateMasterDataReducer,
  createTemplate:createTemplateReducer,
  groupOfContact:groupOfContactReducer,
  contactsFromGroup:contactsFromGroupReducer,
  quickReply:quickReplyReducer,
  assign: assignReducer,
  blockContact:blockContactReducer,
  downloadChat:downloadChatReducer,
  apiKey:apiKeyReducer,
  getRcsAccount: getRcsAccountReducer,
  campaignRcsData: campaignRcsDataReducer,
  sentRcsCampaign: sentRcsCampaignReducer,
  chatbot : chatbotReducer,
  chatbotList : chatbotListReducer,
  campaignRcsList: campaignRcsListReducer,
  downloadCenter : downloadCenterReducer
});

export { rootPersistConfig, rootReducer };
