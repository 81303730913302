import { createSlice } from "@reduxjs/toolkit";
import { CreateRcsAccount, UpdateRcsAccount } from "../../@types/createRcsAccount";
import { dispatch } from "../store";
import axios from '../../utils/axios';
import URLConstants from "../../constants/urlConstants";

type GetRcsAccountState = {
    isLoading: boolean;
    error: boolean;
    getRcsAccount: CreateRcsAccount[];
}

const initialState: GetRcsAccountState = {
    isLoading: false,
    error: false,
    getRcsAccount: [] 
}

const slice = createSlice({
    name: 'getRcsAccount',
    initialState,
    reducers: {
        startLoading(state){
            state.isLoading = true;
        },

        hasError(state, action){
            state.isLoading = false;
            state.error = action.payload;
        },

        getRcsAccountSuccess(state, action) {
            state.isLoading = false;
            state.getRcsAccount = action.payload;
        }
    }
})

export default slice.reducer

export function getAllRcsAccount() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(URLConstants.GET_RCS_ACCOUNT_BASE_URL);
            dispatch(slice.actions.getRcsAccountSuccess(response.data.data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}