import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { CampaignType } from "./campaignData";

export type RcsCampaignType = {
    campaignName: string;
    scheduleTime: string;
    language: string;
    from: string;
    templateId: string;
    templateName: string;
    variables: string;
    to: string;
    toContacts: any;
    s3MediaUrl : string;
    s3MediaName : string;
    message: string
}


type RcsCampaignState = {
    isLoading: boolean;
    error: boolean;
    campaignRcsData: RcsCampaignType;
}

const initialState: RcsCampaignState = {
    isLoading: false,
    error: false,
    campaignRcsData: {} as RcsCampaignType,
}

const slice = createSlice({
    name: 'rcsCampaignData',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getRcsCampaignDataSuccess(state, action) {
            state.isLoading = false;
            state.campaignRcsData = action.payload;
        },
    }
})

export default slice.reducer;

export function getRcsCampaignName(values: any) {
    return async () => {
        try{
            const response = {
                "campaignName": values.campaignName, 
                "scheduleTime": values.scheduleTime,
                "toContacts": values.toContacts,
                "s3MediaUrl" :values.s3MediaUrl,
                "s3MediaName" :values.s3MediaName,
                "templateId": values.templateId,
                "templateName": values.templateName,
                "message": values.message,
                "language": values.language,
                "rcsAccountId": values.rcsAccountId
            }
            dispatch(slice.actions.getRcsCampaignDataSuccess(response))
        }catch(error: any){
            dispatch(slice.actions.hasError(error))
        }
    }
}

