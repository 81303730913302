import { createSlice } from "@reduxjs/toolkit";
import { CreateRcsAccount, UpdateRcsAccount } from "../../@types/createRcsAccount";
import URLConstants from "../../constants/urlConstants";
import axios from "../../utils/axios";
import { dispatch } from "../store";

type CreateRcsAccountState = {
    isLoading: boolean;
    error: boolean;
    createRcsAccount: CreateRcsAccount;
    updateRcsAccount: UpdateRcsAccount;
}

const initialState: CreateRcsAccountState = {
    isLoading: false,
    error: false,
    createRcsAccount: {} as CreateRcsAccount,
    updateRcsAccount: {} as UpdateRcsAccount
}

const slice = createSlice({
    name: 'createRcsAccountState',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        createRcsAccountSuccess(state, action) {
            state.isLoading = false;
            state.createRcsAccount = action.payload;
        },

        updateRcsAccountSuccess(state, action) {
            state.isLoading = false;
            state.updateRcsAccount = action.payload;
        },
    }
})

export default slice.reducer;

export function createRCSAccount(values: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(URLConstants.CREATE_RCS_ACCOUNT_BASE_URL,{"displayName":values.displayName,"email":values.email,"accountKey":values.accountKey})
            dispatch(slice.actions.createRcsAccountSuccess(response.data.data))
        } catch (error: any) {
            dispatch(slice.actions.hasError(error))
            return {message: error?.message, hasError: true}
        }
    }
}

export function updateRcsAccount(rcsAccountId:string, values: any) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${URLConstants.UPDATE_RCS_ACCOUNT_BASE_URL}/${rcsAccountId}`,{"displayName":values.displayName})
            dispatch(slice.actions.updateRcsAccountSuccess(response.data.data))
        } catch (error: any) {
            dispatch(slice.actions.hasError(error));
            return {message: error?.message, hasError: true}
        }
    }
}