import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AllContacts } from '../../@types/allContacts';
import URLConstants from '../../constants/urlConstants';

export type CampaignListType = {
      campaignId: string;
        scheduleTime: Date;
        campaignName:string;
        status:string;
}

type CampaignListState = {
    isLoading: boolean;
    error: boolean;
    campaignList: CampaignListType[];
    campaignListBeta: CampaignListType[];
}

const initialState: CampaignListState = {
    isLoading: false,
    error: false,
    campaignList: [],
    campaignListBeta: [],
}

const slice = createSlice({
    name: 'campaignList',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ALL CAMPAIGNS
        getCampaignListSuccess(state, action) {
            state.isLoading = false;
            state.campaignList = action.payload;
        },

            // GET ALL BETA CAMPAIGNS
            getCampaignListBetaSuccess(state, action) {
                state.isLoading = false;
                state.campaignListBeta = action.payload;
            },
    }
});

// Reducer
export default slice.reducer;


export function getCampaignList(page:number,records:number,name: string) {
    var params = {
        "page":page,
        "records":records,
        "campaignName" : name
      }
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(URLConstants.ALL_CAMPAIGN_BASE_URL,{params});
                dispatch(slice.actions.getCampaignListSuccess(response.data.data));
            } catch (error) {
                dispatch(slice.actions.hasError(error));
            }
        };
}

export function getCampaignListBeta(page:number,records:number,name: string) {
    var params = {
        "page":page,
        "records":records,
        "campaignName" : name
      }
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(URLConstants.ALL_CAMPAIGN_V4_BASE_URL,{params});
                dispatch(slice.actions.getCampaignListBetaSuccess(response.data.data));
            } catch (error) {
                dispatch(slice.actions.hasError(error));
            }
        };
}



