import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { AllContacts } from '../../@types/allContacts';
import URLConstants from '../../constants/urlConstants';

export type CampaignRcsListType = {
    campaignId: string;
      scheduleTime: Date;
      campaignName:string;
      status:string;
}

type CampaignRcsListState = {
  isLoading: boolean;
  error: boolean;
  campaignRcsList: CampaignRcsListType[];
}

const initialState: CampaignRcsListState = {
  isLoading: false,
  error: false,
  campaignRcsList: [],
}

const slice = createSlice({
  name: 'campaignRcsList',
  initialState,
  reducers: {
      // START LOADING
      startLoading(state) {
          state.isLoading = true;
      },

      // HAS ERROR
      hasError(state, action) {
          state.isLoading = false;
          state.error = action.payload;
      },

      // GET ALL CAMPAIGNS
      getCampaignListSuccess(state, action) {
          state.isLoading = false;
          state.campaignRcsList = action.payload;
      },
  }
});

// Reducer
export default slice.reducer;

export function getRcsCampaignList(page: number, records: number) {
    var params = {
        "page": page,
        "records": records
    }

    return async () => {
        dispatch(slice.actions.startLoading());
        try{
            const response = await axios.get(URLConstants.RCS_CAMPAIGN_LIST_ALL, {params})
            dispatch(slice.actions.getCampaignListSuccess(response.data.data))
        }catch(error: any){
            dispatch(slice.actions.hasError(error))
        }
    }
}