import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { CampaignStatsType } from '../../@types/campaignStatsType';


type CampaignStatsState = {
    isLoading: boolean;
    error: boolean;
    campaignStats: CampaignStatsType;
    campaignV4Stats: CampaignStatsType;
}

const initialState: CampaignStatsState = {
    isLoading: false,
    error: false,
    campaignStats: {} as CampaignStatsType,
    campaignV4Stats: {} as CampaignStatsType
}

const slice = createSlice({
    name: 'campaignStats',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET CAMPAIGN STATS
        getCampaignStatsSuccess(state, action) {
            state.isLoading = false;
            state.campaignStats = action.payload;
        },

        // GET V4 CAMPAIGN STATS
        getCampaignV4StatsSuccess(state, action) {
            state.isLoading = false;
            state.campaignStats = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;


export function getCampaignStats(campaignId:string) {
        return async () => {
            dispatch(slice.actions.startLoading());
            try {
                const response = await axios.get(`https://api.telinfy.net/gaas/v2/cloud/campaigns/${campaignId}/stats`);
                dispatch(slice.actions.getCampaignStatsSuccess(response.data.data));
            } catch (error) {
                dispatch(slice.actions.hasError(error));
            }
        };
}

export function getCampaignV4Stats(campaignId:string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`https://api.telinfy.net/inter-gcms/v4/whatsapp/campaigns/${campaignId}/stats`);
            dispatch(slice.actions.getCampaignV4StatsSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
